import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { FiPhoneCall } from "react-icons/fi"

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[45]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[45]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[18]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[25]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[400px] pb-[100px] md:pb-[120px] md:pt-[280px] md:flex block ">
          <div className=" w-full md:w-[50%] flex flex-col md:justify-start justify-center">
            <h1 className="text-white text-center md:text-start">
              {rpdata?.dbSlogan?.[4].slogan}
            </h1>
            <p className="text-white text-center md:text-start">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:justify-start text-start">
              <ButtonContent />
            </div>
          </div>
          <div className=" w-full md:w-[50%] h-auto flex justify-center items-center md:pt-0 pt-28">
            <div className="pulso flex justify-center items-center">
              <div className="z-30 w-[120px] h-[120px] text-white rounded-full flex justify-center items-center">
                <a
                  href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                >
                  <FiPhoneCall fontSize={35} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
